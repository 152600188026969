import styled from "styled-components"
import Checkmark from "../assets/checkmark.svg"
import Close from "../assets/close.svg"
import { Col } from "antd"

export const Wrapper = styled.div`
  padding: 50px 20px;
  margin: auto;
  max-width: 1300px;
`;

export const Info = styled.div`
  display: flex;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  @media(max-width: 1200px){
    flex-direction: column
  }
`;

export const ImagePart = styled.div`
  width: 50%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media(max-width: 1200px){
    width: 100%;
    img {
      max-height: 300px;
    }
  }
`;

export const TextPart = styled.div`
  width: 50%;
  padding: 55px 40px;
  p {
    margin-top: 40px;
    font-size: 18px
  }
  @media(max-width: 1200px){
    width: 100%;
    padding: 20px;
    p {
      margin-top: 20px;
      font-size: 16px
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 5%;
  row-gap: 20px;
  @media(max-width: 1024px){
    flex-direction: column
  }
`;

export const ServicesContainer = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  // text-align: ${props => !props.whyArmenia && "center"};
  width: ${props => props.whyArmenia  ? "100%" : "30%"} ;
  h4 {
    font-size: 18px;
    text-align: center;
  }
  p {
    font-size: 14px;
  }
  p.currency {
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  p.currency span {
     color: #00B3C7;
  }
  @media(max-width: 1024px){
    width: 100%;
    margin-bottom: 10px;
    padding: ${props=> props.landing && "20px"}
  }
`;

export const List = styled.p`
  display: flex;
  &:before {
    content: ${props=>props.checked ? `url(${Checkmark})` : `url(${Close})`};
    color: black;
    padding: 0 10px 0 0;
    height: 20px;
  }:
`;

export const Price = styled.h2`
  font-size: 36px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom:10px; 
  span {
    font-size: 16px;
    white-space: nowrap;
  }
  span:not(.currency){
    margin-right: 10px;
  }
  span.currency {
    margin-left: 10px;
    color: #00B3C7;
  }
`;

export const FooterNumberWrap = styled.div`
  width: 250px;
  height: 15px;
  margin: ${props=>props.landing ? "20px auto" : "0 0 0 16px"};
  font-family: ArialAMU;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  // margin-left: 16px;
  color: #ffffff;
  @media only screen and (max-width: 768px) {
    width: 220px;
    height: 15px;
    font-family: ArialAMU;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @media only screen and (max-width: 416px) {
    width: 100%;
    height: 15px;
    font-family: ArialAMU;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-left:0;
  }
`
export const FooterNumberColumn = styled(Col)`
    display: flex;
    padding-left: 0%;
    justify-content: center;
    margin-top: 30px;
    ${props=>props.landing && `
      flex-direction: column;
      text-align: center; 
      margin: 60px auto 0;
      img {
        margin: 0 7px;
      }
    `}
    img {
      cursor: pointer;
    }
    button {
      background: transparent;
      border: none;
    }
    
  `;

export const PhoneWrapper = styled.img`
  width: 22px;
  height: 22px;
  margin-left:0;
`
export const WhatsappWrapper = styled.img`
  width: 22px;
  height: 22px;
`
export const ViberWrapper = styled.img`
  width: 20px;
  height: 21px;
`
export const TelegramWrapper = styled.img`
  width: 20px;
  height: 20px;
`
